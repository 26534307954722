@charset "UTF-8";
/*

Style   : MobApp CSS
Version : 1.0
Author  : Surjith S M
URI     : https://surjithctly.in/

Copyright © All rights Reserved 

*/
/*------------------------
[TABLE OF CONTENTS]

1. GLOBAL STYLES
2. NAVBAR
3. HERO
4. TABS
5. TESTIMONIALS
6. IMAGE GALLERY
7. PRICING
8. CALL TO ACTION
9. FOOTER

------------------------*/
/* GLOBAL
----------------------*/
body {
  font-family: "Rubik", sans-serif;
  position: relative;
}

a {
  color: white;
}

a:hover,
a:focus {
  color: #E8786a;
}

h1 {
  font-size: 60px;
  font-weight: 300;
  letter-spacing: -1px;
  margin-bottom: 1.5rem;
}

h2 {
  font-size: 45px;
  font-weight: 300;
  color: #Ea6192;
  letter-spacing: -1px;
  margin-bottom: 1rem;
}

h3 {
  color: #Ea6192;
  font-size: 33px;
  font-weight: 500;
}

h4 {
  font-size: 20px;
  font-weight: 500;
  color: #Ea6192;
}

h5 {
  font-size: 28px;
  font-weight: 300;
  color: #Ea6192;
  margin-bottom: 0.7rem;
}

p {
  color: #959094;
}

p.lead {
  color: white;
  margin-bottom: 2rem;
}

.text-primary {
  color: rgba(255, 255, 255, 0.6) !important;
}

.light-font {
  font-weight: 300;
}

.btn {
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 0.375rem 1.35rem;
  transition: all 0.3s ease;
}

.btn-outline-light:hover {
  color: #Ea6192;
}

.btn-primary {
  border-radius: 3px;
  background-color: #E8786a;
  box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.2);
  font-weight: 500;
  padding: 0.6rem 2rem;
  border: 0;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:not([disabled]):not(.disabled).active,
.btn-primary:not([disabled]):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #e56454;
  box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.3);
  color: #FFF;
}

.btn-light {
  border-radius: 3px;
  background: #FFF;
  box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.26);
  font-size: 14px;
  font-weight: 500;
  color: #Ea6192;
  margin: 0.5rem;
  padding: 0.7rem 1.6rem;
  line-height: 1.8;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 0.8rem 1rem;
  font-size: 15px;
}

.light-bg {
  background-color: #faf6fb;
}

.section {
  padding: 80px 0;
}

.section-title {
  text-align: center;
  margin-bottom: 3rem;
}

.section-title small {
  color: #998a9b;
}

@media (max-width: 767px) {
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 30px;
  }
}
/* NAVBAR
----------------------*/
.nav-menu {
  padding: 1rem 0;
  transition: all 0.3s ease;
}

.nav-menu.is-scrolling,
.nav-menu.menu-is-open {
  background-color: rgba(232, 120, 106, 0.94);
}

.nav-menu.is-scrolling {
  padding: 0;
}

.navbar-nav .nav-link {
  position: relative;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
    font-size: 14px;
  }
  .navbar-nav > .nav-item > .nav-link.active:after {
    content: "";
    border-bottom: 2px solid rgba(255, 255, 255, 0.6);
    left: 1rem;
    right: 1rem;
    bottom: 5px;
    height: 1px;
    position: absolute;
  }
}
@media (max-width: 991px) {
  .navbar-nav.is-scrolling {
    padding-bottom: 1rem;
  }
  .navbar-nav .nav-item {
    text-align: center;
  }
}
/* HERO
----------------------*/
header {
  padding: 100px 0 0;
  text-align: center;
  color: #FFF;
}

.bg-gradient {
  background-image: -moz-linear-gradient(135deg, #E8786a 0%, #Ea6192 100%);
  background-image: -webkit-linear-gradient(135deg, #E8786a 0%, #Ea6192 100%);
  background-image: -ms-linear-gradient(135deg, #E8786a 0%, #Ea6192 100%);
  background-image: linear-gradient(135deg, #E8786a 0%, #Ea6192 100%);
}

.tagline {
  font-size: 23px;
  font-weight: 300;
  color: #ffb8f6;
  max-width: 800px;
  margin: 0 auto;
}

.img-holder {
  height: 0;
  padding-bottom: 33%;
  overflow: hidden;
}

@media (max-width: 1200px) {
  .img-holder {
    padding-bottom: 50%;
  }
}
@media (max-width: 767px) {
  .tagline {
    font-size: 17px;
  }
  .img-holder {
    padding-bottom: 100%;
  }
}
/* FEATURES
----------------------*/
.gradient-fill:before {
  color: #9d1546;
  background: -moz-linear-gradient(top, #a02819 0%, #9d1546 100%);
  background: -webkit-linear-gradient(top, #a02819 0%, #9d1546 100%);
  background: linear-gradient(to bottom, #a02819 0%, #9d1546 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card.features {
  border: 0;
  border-radius: 3px;
  box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease;
}

@media (max-width: 991px) {
  .card.features {
    margin-bottom: 2rem;
  }
  [class^=col-]:last-child .card.features {
    margin-bottom: 0;
  }
}
.card.features:before {
  content: "";
  position: absolute;
  width: 3px;
  color: #9d1546;
  background: -moz-linear-gradient(top, #a02819 0%, #9d1546 100%);
  background: -webkit-linear-gradient(top, #a02819 0%, #9d1546 100%);
  background: linear-gradient(to bottom, #a02819 0%, #9d1546 100%);
  top: 0;
  bottom: 0;
  left: 0;
}

.card-text {
  font-size: 14px;
}

.card.features:hover {
  transform: translateY(-3px);
  -moz-box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.08);
}

.box-icon {
  box-shadow: 0px 0px 43px 0px rgba(0, 0, 0, 0.14);
  padding: 10px;
  width: 70px;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  background-color: #FFF;
}

.circle-icon {
  box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.07);
  padding: 10px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 1.5rem;
  background-color: #FFF;
  color: #E8786a;
  font-size: 48px;
  text-align: center;
  line-height: 80px;
  font-weight: 300;
  transition: all 0.3s ease;
}

@media (max-width: 992px) {
  .circle-icon {
    width: 70px;
    height: 70px;
    font-size: 28px;
    line-height: 50px;
  }
}
.ui-steps li:hover .circle-icon {
  background: #E8786a;
  box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.09);
  color: #FFF;
}

.ui-steps li {
  padding: 15px 0;
}

.ui-steps li:not(:last-child) {
  border-bottom: 1px solid #f8e3f0;
}

.perspective-phone {
  position: relative;
  z-index: -1;
}

@media (min-width: 992px) {
  .perspective-phone {
    margin-top: -150px;
  }
}
/*  TABS
----------------------*/
.tab-content {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #FFF;
  box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.04);
  padding: 3rem;
}

@media (max-width: 992px) {
  .tab-content {
    padding: 1.5rem;
  }
}
.tab-content p {
  line-height: 1.8;
}

.tab-content h2 {
  margin-bottom: 0.5rem;
}

.nav-tabs {
  border-bottom: 0;
}

.nav-tabs .nav-item .nav-link,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  padding: 1rem 1rem;
  border-color: #faf6fb #faf6fb #FFF;
  font-size: 19px;
  color: #b5a4c8;
  background: #f5eff7;
}

.nav-tabs .nav-link.active {
  background: #FFF;
  border-top-width: 3px;
  border-color: #ce75b4 #faf6fb #FFF;
  color: #Ea6192;
}

/*  TESTIMONIALS
----------------------*/
.owl-carousel .owl-item img.client-img {
  width: 110px;
  margin: 30px auto;
  border-radius: 50%;
  box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.07);
}

.testimonials-single {
  text-align: center;
  max-width: 80%;
  margin: 0 auto;
}

.blockquote {
  color: #7a767a;
  font-weight: 300;
}

.owl-next.disabled,
.owl-prev.disabled {
  opacity: 0.5;
}

.owl-prev,
.owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.owl-prev {
  left: 0;
}

.owl-next {
  right: 0;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 60px;
}

.owl-theme .owl-dots .owl-dot span {
  background: #e7d9eb;
  width: 35px;
  height: 8px;
  border-radius: 10px;
  transition: all 0.3s ease-in;
}

.owl-theme .owl-dots .owl-dot:hover span {
  background: #ff487e;
}

.owl-theme .owl-dots .owl-dot.active span {
  background: #ff487e;
  box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.07);
}

/*  IMAGE GALLERY
----------------------*/
.img-gallery .owl-item {
  box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.07);
  transform: scale(0.8);
  transition: all 0.3s ease-in;
}

.img-gallery .owl-item.center {
  transform: scale(1);
}

/*  PRICING
----------------------*/
@media (max-width: 992px) {
  .card-deck {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .card-deck .card {
    margin-bottom: 15px;
  }
}
.card.pricing {
  border: 1px solid #f1eef1;
}

.card.pricing.popular {
  border-top-width: 3px;
  border-color: #ce75b4 #faf6fb #FFF;
  box-shadow: 0px 12px 59px 0px rgba(36, 7, 31, 0.11);
  color: #Ea6192;
}

.card.pricing .card-head {
  text-align: center;
  padding: 40px 0 20px;
}

.card.pricing .card-head .price {
  display: block;
  font-size: 45px;
  font-weight: 300;
  color: #Ea6192;
}

.card.pricing .card-head .price sub {
  bottom: 0;
  font-size: 55%;
}

.card.pricing .list-group-item {
  border: 0;
  text-align: center;
  color: #959094;
  padding: 1.05rem 1.25rem;
}

.card.pricing .list-group-item del {
  color: #d9d3d8;
}

.card.pricing .card-body {
  padding: 1.75rem;
}

/*  CALL TO ACTION
----------------------*/
.call-to-action {
  text-align: center;
  color: #FFF;
  margin: 3rem 0;
}

.call-to-action .box-icon {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  transform: scale(0.85);
  margin-bottom: 2.5rem;
}

.call-to-action h2 {
  color: #FFF;
}

.call-to-action .tagline {
  font-size: 16px;
  font-weight: 300;
  color: #ffb8f6;
  max-width: 650px;
  margin: 0 auto;
}

.btn-light img {
  margin-right: 0.4rem;
  vertical-align: text-bottom;
}

/*  FOOTER
----------------------*/
.social-icons {
  text-align: right;
}

.social-icons a {
  background-color: #FFF;
  box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.05);
  width: 50px;
  height: 50px;
  display: inline-block;
  text-align: center;
  line-height: 50px;
  margin: 0 0.3rem;
  border-radius: 5px;
  color: #f4c9e2;
  transition: all 0.3s ease;
}

.social-icons a:hover {
  text-decoration: none;
  color: white;
}

@media (max-width: 991px) {
  .social-icons {
    text-align: center;
    margin-top: 2rem;
  }
}
/* EXTRAS */
h4.howtolist {
  font-size: 28px;
  font-weight: 400;
  color: #e43473;
  margin-bottom: 0.7rem;
}

.howtodescription {
  color: #444;
}

.navbar-brand {
  font-size: 1.75em;
}

.upper {
  font-weight: 500;
}

.lower {
  font-weight: 100;
}

#contactbar img {
  filter: opacity(0.3);
  transition: all 0.3s ease;
  margin-top: 2px;
}

#contactbar img:hover {
  filter: opacity(1);
}

.social-icons a {
  color: #888;
  transition: all 0.3s ease;
}

.social-icons a.twitter:hover {
  color: #1FA1F1;
}

.social-icons a.github:hover {
  color: #000000;
}

.social-icons a.email:hover {
  color: #F16E17;
}

footer {
  font-size: 0.8rem;
  color: #959094;
}

footer a {
  color: #959094;
  text-decoration: none;
}

footer.my-5 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

#contactbar.py-5 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.img-gallery .owl-item {
  box-shadow: none !important;
}

.middleman {
  font-size: 30px;
  font-weight: 100;
}

@media (max-width: 991px) {
  .middleman {
    font-size: 20px;
  }
}
@media (max-width: 991px) {
  .trademarks {
    display: none;
  }
}
.circle-icon {
  font-weight: 700;
}

.jumbo {
  margin-top: 4em !important;
}

@media (max-width: 991px) {
  .jumbo {
    margin-top: 2em !important;
  }
  .copyrightable {
    text-align: center;
  }
}
.card-text {
  color: #666;
}

h2 {
  font-size: 30px;
  font-weight: 100;
  color: #959094;
}

.owl-dot, .owl-dot:focus, .owl-dot:active {
  border: 0 !important;
  background: white !important;
  outline: 0;
}

.section {
  padding: 60px 0px !important;
}

.magicbutton {
  transition: transform 0.1s;
}

.magicbutton:hover {
  transform: scale(1.05);
  background: #FFF;
}

.magicbutton:hover i {
  color: black;
}

.section-title small {
  color: #6D6D6D;
  font-size: 90% !important;
}

.owl-stage-outer {
  padding: 2em 0;
}

.img-gallery .owl-item.center {
  transform: scale(1.2);
}

@media (max-width: 991px) {
  .img-gallery .owl-item.center {
    transform: scale(1);
  }
}
@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

#sending {
  display: none;
}

#sent {
  display: none;
  color: green;
}

.has-error {
  border: 1px solid red !important;
}

/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  -moz-backface-visibility: hidden;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item, .owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
  display: none;
}

.no-js .owl-carousel, .owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.owl-height {
  transition: height 0.5s ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 0.4s ease;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 0.1s ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.4s ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
.owl-theme .owl-dots, .owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav {
  margin-top: 10px;
}

.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
}

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}


